<script setup>
import { useForm } from '@inertiajs/vue3';
import { inject } from 'vue';

import Button from '@/Components/Button/Button.vue';
import Modal from '@/Components/Popup/Modal.vue';

const route = inject('route');

const props = defineProps({
    showing: Boolean,
    company: Object,
});

const emits = defineEmits(['update:showing']);

const form = useForm({});

const sendForm = () => {
    form.delete(route('staff.companies.destroy', props.company.id), {
        onSuccess: closeForm,
    });
};

const closeForm = () => emits('update:showing', false);
</script>

<template>
    <Modal :open="showing" @closing="closeForm" class="sm:max-w-[800px]">
        <template #default>
            <div class="flex flex-col space-y-3 mt-4">
                <p>
                    {{ $t('Depending on the type of user, the following data will be removed') }}
                </p>

                <h3 class="text-xl font-semibold">{{ $t('Employer') }}</h3>
                <ul class="list-disc list-inside">
                    <li>{{ $t('All {model} belonging to the user', { model: $t('locations') }) }}</li>
                    <li>{{ $t('All {model} belonging to the user', { model: $t('companies') }) }}</li>
                    <li>{{ $t('Owner of the selected company') }}</li>
                </ul>

                <h3 class="text-xl font-semibold">{{ $t('Freelancer') }}</h3>
                <ul class="list-disc list-inside">
                    <li>{{ $t('Active applications') }}</li>
                    <li>{{ $t('Owner of the selected company') }}</li>
                </ul>

                <p>{{ $t('Are you sure you want to delete this {model}?', { model: $t('company') }) }}</p>
            </div>
        </template>
        <template #button-bar>
            <Button white @click="closeForm">
                {{ $t('Cancel') }}
            </Button>
            <Button @click="sendForm" :processing="form.processing" :recentlySuccessful="form.recentlySuccessful">
                {{ $t('Delete') }}
            </Button>
        </template>
    </Modal>
</template>
